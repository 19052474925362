.assessment-home {
  margin-left: 5vw;
  font-family: "Poppins";
  margin-bottom: 100px;
  .assesWrapper {
    // width: fit-content;
    width: 90%;
    // min-width: 590px;
    max-width: 600px;
    background: #fff;
    padding: 8px 16px;
    position: relative;
    margin-top: 43px;

    border-radius: 8px;
    box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.03);

    .icon1 {
      position: absolute;
      right: 20px;
      top: 0;
    }

    .first {
      color: #0bb762;
      font-family: "Poppins";
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .second {
      color: #212121;
      font-family: "Poppins";
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 7px;
      .joboffers {
        color: #062b6e;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
        margin-left: 14px;
        cursor: pointer;
      }
    }
  }
  .assessment-details-main {
    display: flex;
    justify-content: space-between;
    .assessment-details {
      margin-top: 60px;
      width: 100%;
      & > div:nth-child(1) {
        color: #212121;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .desc-main {
        margin-top: 16px;
        & > div {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 10px;
          & > div {
            margin: 0;
            padding: 0;
            color: #8d8d8d;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 6px;
          }
          & > span {
            margin: 0;
            padding: 0;
            img {
              width: 6px;
              height: 6px;
            }
          }
        }
      }
      .assessment-card-wrapper {
        margin-top: 40px;
        .assessment-cards {
          display: flex;
          align-items: center;
          gap: 38px;
          opacity: 0.6;
          .round-details {
            padding: 16px 20px;
            border-radius: 8px;
            background: #fff;
            box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.03);
            display: flex;
            gap: 20px;
            // width: 100%;
            width: 500px;
            // border: 1px solid red;
            // max-width: 527px;
            .external-ring {
              // padding: 8px;
              border-radius: 50%;
              width: 24px;
              height: 24px;
              // overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              .round-number {
                border: 1px solid #212121;
                opacity: inherit;
                height: 24px;
                width: 24px;
                border-radius: 50%;

                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .round-names {
              flex: 1;
              & > div:nth-child(1) {
                color: #212121;
                font-family: "Poppins";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 22.4px */
              }
              & > div:nth-child(2) {
                color: #4f4f4f;
                font-family: "Poppins";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
                margin-top: 8px;
              }
              .failed {
                color: #f00;
                font-family: "Poppins";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 5px;
              }
              .completed {
                color: rgb(45, 151, 45);
                margin-top: 12px;
                font-weight: 600;
                font-size: 1rem;
              }
              .continue {
                & > button {
                  border-radius: 8px;
                  background: #062b6e;
                  border: none;
                  outline: none;
                  padding: 11px 20px;
                  color: #fff;
                  font-family: "Poppins";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 140%; /* 22.4px */
                  margin-top: 16px;
                  cursor: pointer;
                }
              }
            }
          }
          .round-image {
            // height: 100%;
            height: 83px;
            width: 114px;
            & > img {
              height: 100%;
              width: 100%;
            }
          }
        }
        .vertical-line {
          height: 36px;
          border-left: 1px dashed #858585;
          // border-left: 1px dashed #34a853;
          margin-left: 30px;
        }
      }
    }
    .right-wrapper {
      width: 400px;
      margin-top: 120px;
      border-radius: 2px;
      background: rgba(204, 222, 255, 0.5);
      padding: 30px;
      height: fit-content;
      font-family: "Poppins";
      text-align: center;
      padding-bottom: 70px;
      margin-right: 60px;
      .text-1 {
        color: #4f4f4f;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }
      .text-2 {
        color: #4f4f4f;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-top: 10px;
      }
      .text-3 {
        color: #212121;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 137.5% */
        margin-top: 18px;
      }
      .img1 {
        & > img {
          width: 251.491px;
          height: 250.38px;
        }
      }
      .divider {
        border-bottom: 1px solid #cec9e6;
        margin-top: 24px;
      }
      .slider-main {
        margin-top: 24px;
        .slick-slider {
          display: flex;
          // position: relative;
          .arrow1,
          .arrow2 {
            position: absolute;
            bottom: -50px;
            z-index: 100;
          }
          .arrow1 {
            right: 0;
          }
          .arrow2 {
            left: 0;
          }
          .slick-dots {
            bottom: -50px;
          }
        }
        .each-item {
          text-align: center;
          .heading {
            width: fit-content;
            display: flex;
            gap: 18px;
            align-items: center;
            margin: 0 auto;
            & > div:nth-child(2) {
              color: #212121;
              text-align: center;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 22px; /* 122.222% */
            }
          }
          .desc-main {
            border-radius: 6px;
            background: #6f8fcb;
            padding: 16px;
            margin-top: 30px;
            .desc1 {
              color: #fff;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px; /* 137.5% */
            }
            .details {
              display: flex;
              align-items: center;
              gap: 16px;
              margin-top: 20px;
              .person {
                & > img {
                  width: 44px;
                  height: 44px;
                }
              }
              & > div:nth-child(2) {
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px; /* 137.5% */
              }
            }
          }
        }
      }
    }
  }
}
