.PreProctoringScreenRound1 {
  margin-left: 5vw;
  font-family: "Poppins";
  margin-top: 42px;
  .back-to-home {
    width: fit-content;
    display: flex;
    cursor: pointer;
    font-family: "Poppins";
    // align-items: center;
    color: var(--black-high, #212121);
    // text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    // border: 1px solid;
    gap: 10px;

    & > div:nth-child(2) {
      margin-top: 4px;
    }
  }
  .details-container {
    .heading {
      color: #212121;
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 41px;
    }
    .heading2 {
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 24px;
    }
    .details {
      .each-item {
        display: flex;
        gap: 17px;
        margin-top: 29px;
        .listitem {
          color: #8d8d8d;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          & > span {
            & > b {
              color: #585858;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
      }
    }
    .agree {
      margin-top: 42px;
      border-radius: 8px;
      background: #062b6e;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      padding: 13px 30px;
      margin-bottom: 100px;
    }
  }
  .block-button {
    border-radius: 8px;
    border: 1px solid #777;
    color: #777;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 12px 24px;
  }
}

.otpVerificationContainer {
  position: relative;
  padding: 40px 24px;
  font-family: "Poppins";
  min-width: 480px;
  .close {
    position: absolute;
    top: 20px;
    right: 25px;
    cursor: pointer;
  }

  .greenCheck {
    text-align: center;
    margin-top: 40px;
    & > img {
      height: 40px;
      width: 40px;
    }
  }
  .verificationMessage {
    text-align: center;
    color: #2f2f2f;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 auto;
    margin-top: 16px;
  }
  .title,
  .note {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .note {
    text-align: center;
  }
  .message {
    color: var(--black-high, #212121);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-top: 10px;
    & > span {
      color: var(--black-high, #212121);
      font-size: 16px;
      font-weight: 500;
    }
  }
  .noteMessage {
    color: var(--black-high, #212121);
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 404px;
    text-align: center;
    margin: 0 auto;
    margin-top: 25px;
  }
  .enterOtp {
    color: #000;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-top: 30px;
    line-height: 140%; /* 19.6px */
  }
  .otpInput {
    max-width: 90%;
    & > input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
      outline: none;
      padding: 13px 16px;
      color: #000;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      margin-top: 8px;
    }
    .wrong {
      border: 1px solid red;
    }
    & > input::placeholder {
      color: rgba(146, 146, 146, 0.6);
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
    }
    & > input::-webkit-outer-spin-button,
    & > input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .wrong-error {
    display: flex;
    align-items: center;
    color: #e5261c;
    font-family: "Poppins";
    margin-top: 6px;
    gap: 6px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 18.2px */
  }
  .notRecieved {
    color: #7d7d7d;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    & > div:nth-child(2) {
      & > span {
        color: #d00909;
      }
      & > button {
        border: none;
        outline: none;
        background: inherit;
        color: #0b52d4;
        text-align: right;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
      }
    }
  }
  .submit {
    text-align: center;
    & > button {
      border-radius: 8px;
      background: #062b6e;
      border: none;
      outline: none;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 12px 40px;
      margin: 0 auto;
      margin-top: 30px;
      cursor: pointer;
    }
  }
  .beforeStartButtons {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 30px;
    & > button:nth-child(1) {
      border-radius: 8px;
      border: 1px solid #777;
      outline: none;
      background-color: inherit;
      color: #777;
      font-family: "Poppins";
      padding: 12px 24px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
    }
    & > button:nth-child(2) {
      border-radius: 8px;
      background: #062b6e;
      border: none;
      outline: none;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 12px 24px;
      cursor: pointer;
    }
  }
}
