.small-right-container {
  width: 100%;
  // max-width: 1300px;
  margin: 0 auto;
  padding: 40px 20px;
  // background: #f6f4ff;
  background: #fff;
  // box-shadow: 6px 6px 4px 0px rgba(79, 79, 79, 0.04);
  @media (max-width: 1400px) {
    padding: 20px;
    width: calc(100% - 40px);
  }

  .page-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 58px;
    letter-spacing: 0.2px;
    color: #212121;
    text-align: center;
    margin-bottom: 106px;
  }
  .wrapper {
    width: 100%;
    max-width: 1300px;
    // width: fit-content;
    margin: 0 auto;
    // border: 1px solid;
    display: flex;
    justify-content: space-between;
    height: fit-content;
    align-items: center;
    @media (max-width: 900px) {
      flex-wrap: wrap;
      gap: 50px;
      justify-content: center;
      box-shadow: none;
    }
    .left-side {
      // max-width: 590px;
      font-family: "Poppins";
      font-style: normal;
      .tagline {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
        color: #b1adad;
        & > span {
          color: #212121;
        }
      }
      .title {
        font-weight: 700;
        font-size: 1.9rem;
        line-height: 45px;
        letter-spacing: 1.2px;
        text-transform: capitalize;
        color: #212121;
        display: flex;
        align-items: center;
        margin-top: 8px;
        .item {
          background: #0b52d4;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin: 0 10px;
        }
      }
      .desc {
        font-family: "Poppins";
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0px;
        color: #757575;
        margin-top: 12px;
        max-width: 600px;
      }
      .button-wrapper {
        & > button {
          padding: 15px 40px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 19px;
          text-align: center;
          letter-spacing: 0.46px;
          cursor: pointer;
          margin-top: 50px;
          border: none;
          border-radius: 8px;
        }
        & > button:nth-child(1) {
          // background: #000000;
          background: #062b6e;
          color: #fff;
        }
        & > button:nth-child(2) {
          // border: 1px solid #3563e9;
          // border: 1px solid #303030;
          color: #fff;
          background: #34a853;
          margin-left: 30px;
        }
      }
    }
    .right-image {
      width: 100%;
      //   height: auto;
      max-width: 600px;
      // height: 600px;
      height: fit-content;
      border-radius: 5px;
      img {
        width: 100%;
        // height: 100%;
        height: auto;
      }
    }
  }
}

@media (min-width: 1400px) {
  .small-right-container {
    max-width: 100%;
    padding: 40px 60px;
    width: calc(100% - 120px);
    .wrapper {
      max-width: 100%;
      .left-side {
        .tagline {
          font-size: 16px;
        }
        .title {
          font-size: 34px;
          font-weight: 700;
          text-transform: capitalize;
        }
        .desc {
          font-size: 22px;
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .small-right-container {
    box-shadow: none;
  }
}
@media (max-width: 650px) {
  .small-right-container {
    padding: 0px;
    width: 100%;
    width: 100%;
    overflow: hidden;
    margin: auto;
    // margin-top: 30px;
    padding: 30px 0;
    font-family: "Poppins";
    box-shadow: none;
    .wrapper {
      flex-wrap: nowrap;
      flex-direction: column;
      gap: 32px;
      .left-side {
        padding: 0 16px;
        .tagline {
          color: #b1adad;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
          & > span {
            color: #212121;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
          }
        }
        .title {
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: capitalize;
          margin-top: 6px;
        }
        .desc {
          margin-top: 12px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .button-wrapper {
          & > button {
            margin-top: 30px;
            padding: 10px 24px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.46px;
          }
          & > button:nth-child(2) {
            margin-left: 0px;
          }
        }
      }
    }
  }
}
