.vetting {
  width: 100%;
  // background: #f9f9f9;
  background: #fff;
  box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.1);
  .wrapper {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    gap: 50px;
    padding: 90px 0;
    justify-content: center;
    @media (max-width: 850px) {
      padding: 0 20px;
      gap: 40px;
    }
    .left-side {
      max-width: 400px;
      /* height: 100%; */
      display: flex;
      /* align-items: center; */
      flex-direction: column;
      /* justify-content: flex-end; */
      justify-content: center;
      & > div:nth-child(1) {
        font-family: "Poppins";
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0.2px;
        color: #212121;
        text-align: left;
        text-transform: capitalize;
      }
      & > div:nth-child(2) {
        font-family: "Poppins";

        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 30px;
        margin-top: 21px;
        font-family: "Poppins";
        font-size: 21px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: left;

        /* identical to box height, or 143% */

        color: #a1aeb7;
      }
      & > div:nth-child(3) {
        font-family: "Poppins";

        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 24px;
        /* or 150% */
        margin-top: 8px;
        color: #a1aeb7;
      }
      .cta-wrapper {
        display: flex;
        gap: 32px;
        margin-top: 41px;
        & > div {
          cursor: pointer;
        }
        .main-button {
          button {
            padding: 15px 40px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.46px;
            cursor: pointer;
            background: #34a853;
            color: #fff;
            border: none;
            outline: none;
            border-radius: 8px;
          }
        }
        .main-cta {
          display: flex;
          align-items: center;
          font-family: "Poppins";

          font-style: normal;
          font-weight: 400;
          font-size: 0.9rem;
          line-height: 16px;
          /* identical to box height */

          text-decoration-line: underline;
          gap: 5px;
          color: #424b5a;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .right-side {
      display: flex;
      gap: 36px;
      .no-and-lines {
        .each-item {
          & > div:nth-child(1) {
            font-family: "Ubuntu", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 1.1rem;
            line-height: 31px;
            /* identical to box height, or 148% */
            height: 31px;
            width: 31px;
            border-radius: 50%;
            text-align: center;
            border: 1px solid #c2d1d9;
            color: #a1aeb7;
            background: #fff;
          }
          & > div:nth-child(2) {
            border-left: 1px solid #c2d1d9;
            height: 150px;
            // height: 20px;
            width: 1px;
            margin: 0 auto;
          }
        }
      }
      .main-data {
        .each-data {
          & div:nth-child(1) {
            font-family: "Poppins";

            font-style: normal;
            font-weight: 700;
            font-size: 1.1rem;
            line-height: 31px;
            /* identical to box height, or 148% */
            height: 32px;
            color: #505d68;
          }
          & div:nth-child(2) {
            width: 304px;
            height: 136px !important;
            height: fit-content;
            min-height: 19px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */
            color: #a1aeb7;
            margin-top: 16px;

            font-family: "Poppins";
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .vetting {
    .wrapper {
      flex-direction: column;
      padding: 0 16px;
      gap: 30px;
      width: calc(100% - 32px);
      padding-top: 40px;
      .left-side {
        max-width: 100%;
        & > div:nth-child(1) {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.2px;
          text-align: center;
        }
        & > div:nth-child(2) {
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px; /* 187.5% */
          margin-top: 24px;
        }
        .cta-wrapper {
          margin-top: 30px;
          .main-button {
            margin: 0 auto;
            & > button {
              padding: 9px 16px;
              border-radius: 4px;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 0.46px;
            }
          }
        }
      }
      .right-side {
        width: 100%;
        .no-and-lines {
          .each-item {
            & > div:nth-child(2) {
              height: 72px;
            }
          }
        }
        .main-data {
          .each-data {
            & > div:nth-child(1) {
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 31px; /* 221.429% */
            }
            & > div:nth-child(2) {
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 184.615% */
              width: fit-content;
              margin-top: 4px;
              height: 70px !important;
            }
          }
        }
      }
    }
  }
}
