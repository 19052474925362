.CustomerSupportWrapper {
  //   width: 100%;
  font-family: "Poppins";

  margin-left: 5vw;
  @media (max-width: 1050px) {
    margin: 0 20px;
    width: calc(100% - 40px);
  }

  .title {
    color: #000;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    margin-top: 60px;
  }

  .Subheading {
    display: flex;
    gap: 60px;
    color: #a7a7a7;
    font-style: normal;
    font-weight: 400;
    line-height: 152%; /* 30.4px */
    margin-top: 30px;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%; /* 27.36px */
    border-radius: 2px;

    .active {
      color: #0b52d4;
      border-bottom: 2px solid #0b52d4;
      font-weight: 500;
    }
  }

  .qna {
    width: 100%;
    margin-top: 40px;
    display: flex;
    gap: 40px;
    @media (max-width: 600px) {
      flex-wrap: wrap-reverse;
    }
    .faq-data {
      max-width: 810px;
      width: 80%;
      @media (max-width: 600px) {
        width: 95%;
      }
      .ques-ans {
        margin-bottom: 20px;
        .ques {
          display: flex;
          justify-content: space-between;
          background: #f5f5f5;
          padding: 16px 20px;
          color: #000;
          font-size: 1.15rem;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 28px */
        }
        .ans {
          padding: 16px 20px;
          color: #7b7777;
          font-size: 1.15rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 28px */
          border: 1px solid var(--secondary-100, #e8e8ea);
          background: var(--White, #fff);

          /* shadow-md */
          box-shadow: 0px 12px 24px -6px rgba(24, 26, 42, 0.12);
        }
      }
    }
  }
  .main-dropdown-container {
    max-width: 150px;
    .dropDown-wrapper {
      width: 100%;
      position: relative;
      z-index: 10;
      .placeholder {
        // width: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 18px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.03);
        color: #a7a7a7;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        border-radius: 6px;
        border: 1px solid #000;
        margin-top: 30px;
        background: inherit;
        .value {
          color: #4f4f4f;
          font-weight: 500;
        }
        // & > div:nth-child(2) {
        //   // padding-top: 5px;
        // }
      }
      .options-wrapper {
        position: absolute;
        width: 100%;
        background: #fff;
        color: #4f4f4f;
        font-weight: 500;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        line-height: 140%; /* 22.4px */
        height: fit-content;
        max-height: 200px;
        overflow-y: auto;
        margin-top: 2px;
        z-index: 100;
        & > div {
          padding: 13px 16px;
          border-bottom: 1px solid #e5e2e284;
          cursor: pointer;
        }
        & > div:nth-last-child(1) {
          border-bottom: none;
        }
      }
    }
  }
  .raise-ticket-container {
    width: 306px;
    height: fit-content;
    border-radius: 6px;
    background: #fff;
    box-shadow: 2px 2px 2px 0px rgba(79, 79, 79, 0.11),
      -2px -2px 2px 0px rgba(79, 79, 79, 0.05);
    text-align: center;
    padding: 30px 28px;
    .raise-ticket-wrapper {
      .raise-ticket-icon {
        width: 44px;
        height: 42.949px;
        margin: 0 auto;
      }
      .issue-title {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        margin-top: 8px;
        margin-top: 8px;
      }
      .issue-title-2 {
        color: #7b7777;
        text-align: center;
        font-family: "Poppins";
        font-size: 13px;
        margin-top: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      & > div > button {
        border-radius: 8px;
        background: #062b6e;
        border: none;
        outline: none;
        color: #fff;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        padding: 9px 20px;
        margin-top: 20px;
        cursor: pointer;
      }
      .toIssues {
        margin-top: 14px;
        & > a {
          color: #0b52d4;
          text-align: center;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
          text-decoration: none;
          text-decoration: underline;
        }
      }
    }
  }
}
