.home-main {
  .main-item {
    .small-right-container {
      box-shadow: 6px 6px 4px 0px rgba(79, 79, 79, 0.04);
    }
  }
  .footer-main {
    margin-top: 0px;
  }
}

.services-main {
  width: 100%;
  max-width: calc(100% - 40px);
  margin: 0 auto;
  margin-top: 80px;
  @media (max-width: 1340px) {
    padding: 0 20px;
    width: calc(100% - 40px);
  }
  .header {
    font-family: "Poppins";
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    text-align: center;
    color: #212121;
    margin-bottom: 60px;
  }
  .items-main {
    display: flex;
    gap: 60px;
    .item {
      width: calc(50% - 30px);
      .item-image {
        width: 100%;
        & > img {
          width: 100%;
          height: auto;
          border-top-right-radius: 50px;
        }
      }
      .content {
        padding: 20px 16px;
        border: 1px solid #e0e0e0;
        margin-top: -5px;
        // min-height: 282px;
        border-bottom-right-radius: 50px;
        .title {
          font-family: "Poppins";
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 37px;
          text-align: left;
          margin-top: 4px;
        }
        .desc {
          font-family: "Poppins";
          font-size: 1rem;
          font-weight: 400;
          line-height: 28px;
          margin-top: 12px;
          min-height: 137px;
        }
        .read-more {
          display: none;
        }
        .last-btn {
          display: flex;
          align-items: center;
          border: none;
          outline: none;
          cursor: pointer;
          background: inherit;
          font-family: "Poppins";
          font-size: 1rem;
          color: #0b52d4;
          padding: 0px;
          font-weight: 600;
          line-height: 24px;
          gap: 10px;
          margin-top: 16px;

          img {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .services-main {
    max-width: calc(100% - 80px);

    .header {
      font-size: 34px;
    }
    .items-main {
      .item {
        .content {
          .title {
            font-size: 24px;
            margin-top: 10px;
          }
          .desc {
            font-size: 20px;
            margin-top: 16px;
          }
          .last-btn {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .home-main {
    .main-item {
      .small-right-container {
        box-shadow: none;
      }
      .button-wrapper {
        // border: 1px solid;
        & > button {
          width: 230px;
        }
      }
    }
    .footer-main {
      padding-top: 20px;
    }
  }
  .services-main {
    margin-top: 40px;
    width: calc(100% - 32px);
    padding: 0 16px;
    .header {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.2px;
      margin-bottom: 30px;
    }
    .items-main {
      gap: 60px;
      flex-direction: column;
      .item {
        width: 100%;
        .content {
          padding: 16px;
          .title {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .desc {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
          }
          .descCollapsed {
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .read-more {
            display: block;
            color: #0b52d4;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
          .learn-more {
            display: none;
          }
        }
      }
    }
  }
}
