.topLogoContainer {
  width: calc(100% - 2px);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  display: flex;
  align-items: center;
  background: #f3f7ff;
  box-shadow: -3px 3px 4px 0px rgba(33, 33, 33, 0.08);
  .topLogoWrapper {
    width: 44px;
    height: 44px;
    margin-left: 40px;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
}
