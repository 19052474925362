.successWrapper {
  position: relative;
  padding: 34px;
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .smiley {
    text-align: center;
  }
  .congrats {
    color: #0bb762;
    text-align: center;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 20px;
    text-align: center;
  }
  .message {
    color: #4a4a4a;
    text-align: center;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 14px;
  }
  .buttons {
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 36px;
    margin-top: 30px;
    & > button {
      border: none;
      outline: none;
      padding: 12px 16px;
      font-family: "Poppins";
      background: inherit;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      padding-bottom: 10px;
    }
    & > button:nth-child(1) {
      border-radius: 8px;
      border: 1px solid #062b6e;
      color: #062b6e;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      & > span:nth-child(1) {
        margin-bottom: 4px;
      }
    }
    & > button:nth-child(2) {
      padding-top: 14px;
      border-radius: 8px;
      background: #062b6e;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      & > span:nth-child(1) {
        margin-bottom: 6px;
      }
    }
  }
  .round5 {
    text-align: center;
    & > button {
      border: none;
      outline: none;
      padding: 12px 16px;
      font-family: "Poppins";
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 0 auto;
      gap: 12px;
      padding-bottom: 10px;
      border-radius: 8px;
      background: #062b6e;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 30px;
      color: #fff;
      & > span:nth-child(1) {
        margin-bottom: 6px;
      }
    }
  }
}
