.main-faq {
  width: 100%;
  margin-top: 80px;
  // padding: 0 20px;
  .wrapper {
    width: calc(100% - 160px);
    // max-width: 1280px;
    margin: 0 auto;

    .title {
      color: var(--systemGrey-900, #212121);
      font-family: "Poppins";
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.2px;
      text-transform: capitalize;
      text-align: center;
    }
    .desc {
      margin: 0 auto;
      margin-top: 16px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.2px;
      text-transform: capitalize;
      color: #757575;
    }
    .options {
      display: flex;
      justify-content: center;
      gap: 45px;
      font-family: "Poppins";
      font-style: normal;
      color: #4f4f4f;
      margin-top: 40px;
      font-size: 22px;
      font-weight: 400;
      line-height: 33px;
      letter-spacing: 0px;

      div {
        border-bottom: 1px solid #fff;
        padding: 2px;
        padding-top: 0;
        cursor: pointer;
      }
      .selected {
        border-bottom: 1px solid #0b52d4;
        color: #0b52d4;
      }
    }
    .faq-data {
      background: rgba(204, 222, 255, 0.6);
      margin-top: 40px;
      border-radius: 20px;
      .ques-ans {
        padding: 40px 0;
        margin: 0 40px;
        cursor: pointer;
        box-shadow: inset 0px -1px 0px rgba(79, 79, 79, 0.16);
        .ques-arrow {
          display: flex;
          justify-content: space-between;
          .ques {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.2px;
            color: #000000;
            font-size: 16px;
          }
        }
        .ans {
          color: var(--systemGrey-600, #757575);
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px; /* 171.429% */
          letter-spacing: 0.2px;
          margin-top: 16px;
        }
      }
      & > div:nth-last-child(1) {
        box-shadow: none;
      }
    }
  }
}

@media (min-width: 1400px) {
  .main-faq {
    .wrapper {
      width: calc(100% - 200px);
      .title {
        // font-size: 34px;
      }
      .options {
        font-size: 24px;
      }
      .faq-data {
        .ques-ans {
          .ques-arrow {
            .ques {
              font-size: 20px;
            }
          }
          .ans {
            font-size: 18px;
          }
        }
        & > div:nth-last-child(1) {
          box-shadow: none;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .main-faq {
    margin-top: 40px;
    padding: 0;
    width: 100%;
    .wrapper {
      padding: 0 16px;
      width: calc(100% - 32px);
      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
      }
      .options {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin-top: 24px;
      }
      .faq-data {
        margin-top: 24px;
        border-radius: 8px;
        .ques-ans {
          padding: 20px 0px;
          margin: 0 20px;
          .ques-arrow {
            .ques {
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
          .ans {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
            letter-spacing: 0.2px;
            margin-top: 16px;
          }
        }
      }
    }
  }
}
