.footer-main {
  width: 100%;
  margin-top: 80px;
  // background: #ecf2fe;
  background: #fff;
  box-shadow: -3px -3px 4px 0px rgba(79, 79, 79, 0.1);
  font-family: "Poppins";

  .footerContent {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    @media (max-width: 1340px) {
      // padding: 80px 20px;
      width: calc(100% - 40px);
    }
    .first {
      // color: #fff;
      display: flex;
      align-items: center;
      .logo-wdp {
        width: 83px;
        height: 120px;
        // border-radius: 5px;
        // height: 40px;
        cursor: pointer;
        & > img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .second {
      .each-item {
        display: flex;
        gap: 16px;
        margin-top: 32px;
        color: #212121;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        .location-icon {
          color: #0b52d4;
        }
      }
      & > div:nth-child(1) {
        margin-top: 0px;
      }
      .main-address {
        font-weight: 600;
      }
    }
    .third {
      .contact {
        .details-item {
          a {
            text-decoration: none;
            color: inherit;
            display: flex;
            gap: 16px;
            color: #212121;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            margin-top: 24px;
            // align-items: center;
            .email-icon {
              color: #0b52d4;
            }
            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
        & > div:nth-child(1) {
          margin-top: 0px;
        }
      }
      .social {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-top: 32px;
        svg,
        img {
          width: 40px;
          height: 40px;
          color: #062b6e;
        }
      }
    }
  }
  .copyright {
    width: 100%;
    text-align: center;
    height: 94px;
    background: rgba(204, 222, 255, 0.6);
    color: #fff;
    font-size: 16px;
    font-family: "Poppins";
    line-height: 150%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    // margin-top: 38px;
    color: #212121;
    @media (max-width: 1340px) {
      // margin-top: 0px;
      padding-bottom: 10px;
    }
  }
}

@media (max-width: 650px) {
  .footer-main {
    margin-top: 40px;
    .footerContent {
      flex-direction: column;
      padding: 16px 0;
      width: calc(100% - 32px);
      .first {
        text-align: center;
        & > div {
          margin: 0 auto;
        }
      }
      .second {
        .each-item {
          gap: 12px;
          margin-top: 16px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
        & > div:nth-child(1) {
          margin-top: 26px;
        }
        .main-address {
          font-weight: 600;
        }
      }
      .third {
        .contact {
          margin-top: 18px;
          display: flex;
          gap: 20px;
          .details-item {
            a {
              gap: 12px;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 21px */
              margin-top: 0px;
            }
          }
          & > div:nth-child(2) > a {
            gap: 4px;
          }
        }
        .social {
          justify-content: center;
          margin-top: 16px;
        }
      }
    }
    .copyright {
      height: fit-content;
      padding: 16px 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
